@import "~org/variables";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.triple-feature-layout {
    .feature {
        &:hover {
            text-decoration: none;
            color: $black;
        }

        .feature-image {
            width: 100%;
            aspect-ratio: 2 / 3;
            object-fit: cover;
        }

        .cta-link {
            font-family: $font-avenir-next-regular;
            font-weight: $font-weight-normal;

            &.White {
                color: $white;
            }
        }

        .body-wrapper {
            p {
                margin-bottom: 0;
            }
        }

        .feature-link-image {
            width: 100%;
            aspect-ratio: 9 / 8;
            object-fit: cover;
        }
    }

    .triple-feature-tile:has(.feature-text-over-image) {
        padding: 0;
    }

    .triple-feature-container {
        &:has(.feature-text-over-image) {
            @include media-breakpoint-up(lg) {
                margin: 0;
            }

            .image-heading-container {
                display: flex;
                justify-content: flex-end;
                flex-direction: column;
                align-items: center;
                align-content: center;
                padding-top: 0;
            }
        }

        &:not(:has(.feature-text-over-image)) {
            &.row {
                margin-right: rem(-7px);
                margin-left: rem(-7px);
            }

            .col-lg-4 {
                padding-right: rem(7px);
                padding-left: rem(7px);
            }
        }
    }
}

.feature-text-over-image,
.image-component {
    margin: 0;
    width: 100%;
    position: relative;

    .common-image-component {
        width: 100%;
        object-fit: cover;
        object-position: var(--focal-point-x) var(--focal-point-y);
    }

    .image-heading-container {
        position: absolute;
        display: inline-block;
        width: 100%;
        padding: calc(50%) rem(20px) 0 rem(20px);
        height: 100%;
        overflow: hidden;
        align-content: baseline;

        @include media-breakpoint-up(sm) {
            padding: calc(58%) rem(20px) 0 rem(20px);
        }

        @include media-breakpoint-up(md) {
            padding: calc(58%) rem(20px) 0 rem(20px);
        }

        @include media-breakpoint-up(lg) {
            padding: calc(48%) 0 0 0;
        }

        @include media-breakpoint-down(sm) {
            margin: 0 auto;
        }

        h2,
        p {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin: 0 auto;
        }

        .cta-link {
            background-color: rgba($white, 0.15);

            &:hover {
                background-color: rgba($white, 0.4);
            }
        }

        svg {
            height: rem(24px);
        }
    }

    .title {
        color: $white;
    }

    .text-black {
        color: $black;
    }

    .btn:not(:disabled):not(.disabled) {
        min-width: rem(200px);
        padding: 0 rem(40px);
    }
}
